<template>
  <div class="main">
    <el-card class="main-form" :body-style="{ padding: '0px' }">
      <img v-if="!hideLogo" :src="banner" width="400" class="image">
      <el-divider v-if="!hideLogo" style="margin: 0;" />
      <div style="padding: 14px;">
        <el-form :model="formInline" label-width="80px">
          <el-form-item label="用户名">
            <el-input v-model="formInline.user" placeholder="请输入用户名" />
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="formInline.password" type="password" placeholder="请输入密码" />
          </el-form-item>
          <el-form-item style="margin-left: 70px;">
            <el-button type="primary" @click="onSubmit">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <div class="main-form" />
  </div>
</template>

<script>
import {handleCatchStatus} from '../api/handler';
import {ElMessage} from 'element-plus';
import permission from '../utils/permission';
import ribbon from '@jiangtj/ribbon.js';
import banner from '../image/banner-2.png';
export default {
  data() {
    return {
      formInline: {
        user: '',
        password: ''
      },
      banner,
      hideLogo: process.env.VUE_APP_HIDE_LOGO
    };
  },
  mounted() {
    ribbon({s: 300, target: '.main'});
  },
  methods: {
    onSubmit() {
      const parm = {
        username: this.formInline.user,
        password: this.formInline.password
      };
      this.$api.login(parm).then(data => {
        ElMessage.success({
          message: '登录成功',
          type: 'success'
        });
        sessionStorage.token = data.token;
        sessionStorage.user = JSON.stringify(data.user);
        permission.init(data.token, data.user).then(() => {
          this.$router.push('/');
        });
      }).catch(handleCatchStatus);
      // console.log('submit!');
    }
  }
};
</script>

<style scoped>
.main {
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.el-card__header {
  padding: 0;
}

.title-banner {
  width: 400px;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;
}

.main-form {
  width: 400px;
  margin: 150px auto 0;
  height: 350px;
  opacity: 0.9;
}

</style>
